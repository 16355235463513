<style lang="less">
  .server-status {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      background-color: #FFF;
      padding: 20px;
      .search {
        .fm-input-new-suffix-append {
          .fm-input-new-append {
            color: #FFF;
            border: none;
            background-color: #2F5BEA;
          }
        }
      }
    }
    .content {
      overflow: auto;
      flex: 1;
      position: relative;
    }
  }
</style>

<template>
  <div class="server-status">
      <div class="header">
        <fm-input-new clearable placeholder="请输入服务器名称" @enten="search" @clear="search(null)" class="search">
          <div slot="append">搜索</div>
        </fm-input-new>
        {{label}}
      </div>
      <div class="content" ref="card-list-content">
        <card-list></card-list>
      </div>
  </div>
</template>

<script>
import CardList from './card/list'

function resetSeachStyle () {
  this.removeEventListener('mouseenter', resetSeachStyle)
  this.style.transform = ''
  this.style.boxShadow = ''
}

export default {
  components: { CardList },
  data () {
    return {
      searchKey: null,
      searchIndex: 0,
      label: ''
    }
  },
  methods: {
    search (v) {
      this.$refs['card-list-content'].querySelectorAll('[server-search-result]').forEach(el => resetSeachStyle.bind(el)())
      if (v) {
        this.searchIndex = this.searchKey !== v ? 0 : this.searchIndex
        this.searchKey = v
  
        const items = this.$refs['card-list-content'].querySelectorAll('[server-status-search*="' + v + '"]')
        const index = this.searchIndex++ % items.length
        const item = items[index] || null
  
        this.label = '共' + items.length + '条结果, 当前显示的是第' + (index + 1) + '条'
  
        if (item) {
          item.setAttribute('server-search-result', '')
          item.scrollIntoView()
          item.style.transform = 'scale(1.05)';
          item.style.boxShadow = '0 0 10px 0 #aaa'
          item.addEventListener('mouseenter', resetSeachStyle)
        }
      } else {
        this.searchIndex = 0
        this.searchKey = null
        this.label = ''
      }
    }
  },
  async mounted () {
  }
}
</script>
