/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Fri Apr 09 2021
 */
import { serverRequest, serverConfigurationRequest } from '@/api'
const serverStatusLoader = {}
const serverConfigLoader = {}

export function loadServerStatus (parm, refresh = false) {
    let key = JSON.stringify(parm)
    if (!serverStatusLoader[key] || refresh) {
        serverStatusLoader[key] = new Promise (async resolve => {
            let res = await serverRequest.status(parm)
            if (res.length) {
                resolve(res[0])
            } else {
                resolve(null)
            }
        })
    }
    return serverStatusLoader[key]
}

export function loadServerConfig (parm, refresh = false) {
    let key = JSON.stringify(parm)
    if (!serverConfigLoader[key] || refresh) {
        serverConfigLoader[key] = new Promise (async resolve => {
            let res = await serverConfigurationRequest.get(parm)
            if (res.length) {
                resolve(res[0])
            } else {
                resolve(null)
            }
        })
    }
    return serverConfigLoader[key]
}

let reloadTimer = null
const reloadTimerList = []

export async function startReloadTimer () {
    const now = new Date().getTime()
    const tasks = reloadTimerList.filter(({run, time, loading}) => !loading && now - run >= time).map(timer => {
        return new Promise(async resolve => {
            try {
                timer.loading = true
                await timer.loader()
                timer.loading = false
            } catch (error) {
                timer.loading = false
            }
            timer.run = new Date().getTime()
            resolve(timer.run)
        })
    })
    if (tasks.length) {
        await Promise.all(tasks)
    }
    reloadTimer = setTimeout(startReloadTimer, 1000)
}

export function registerReloadTimer (loader, time) {
    const index = reloadTimerList.findIndex(timer => timer.loader === loader)
    if (index < 0) {
        reloadTimerList.push({loader: loader, time: time, run: new Date().getTime(), loading: false})
    }
    if (reloadTimer === null) { startReloadTimer() }
}

export function removeReloadTimer (loader) {
    const index = reloadTimerList.findIndex(timer => timer.loader === loader)
    if (index > -1) {
        reloadTimerList.splice(index, 1)
    }
}
