<style scoped lang="less">
  .bar {
    background-color: #E5E9F2;
    display: flex;
    height: 10px;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    &.default {
      border-radius: 6px;
      .item {
        &:first-of-type {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-of-type {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        &:only-of-type {
          border-radius: 6px;
        }
      }
    }
    &.label {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      .item {
        &:last-of-type, &:only-of-type {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
  .item {
    width: 0%;
    position: relative;
    height: 100%;
    transition: all .3s;
    &:nth-of-type(1) {
      background-color: #094FFF;
    }
    &:nth-of-type(2) {
      background-color: #6788FA;
    }
    &:nth-of-type(3) {
      background-color: #CCD6FF;
    }
    &.warning {
      background-color: #ed2727;
    }
    &::before {
      content: attr(data-value);
      color: #657180;
      transition: all .3s;
      position: absolute;
      opacity: 0;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      transform: scale(1.1);
      z-index: 1;
      &::before {
        opacity: 1;
        top: -20px;
      }
    }
    &:only-of-type:hover {
      transform: scale(1);
    }
  }
</style>

<template>
  <div class="bar" :class="{'multi': items.length > 1, [theme]: true }">
    <div class="item" :data-value="item + '%'" :class="{
      'warning': item >= 80
    }" :style="{width: (animation ? item : 0) + '%'}" v-for="(item, i) in items" :key="item + '-' + i"></div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array, default () {
        return []
      }
    },
    theme: { type: String, default: 'default' }
  },
  data () {
    return {
      animation: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.animation = true  
    }, 200)
  }
}
</script>
