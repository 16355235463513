<style scoped lang="less">
  .item {
    cursor: pointer;
    padding: 15px;
    padding-bottom: 40px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 8px;
    transition: all .3s;
    transition-delay: .3s;
    box-shadow: 0 0 10px 0 transparent;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
    }
    .status {
      color: rgb(38, 37, 37);
      .warning-num {
        display: inline-block;
        border-radius: 10px;
        background-color: red;
        color: #FFF;
        padding: 0 5px;
      }
    }
  }
  .cell {
    padding: 10px 0;
    display: flex;
    align-items: center;
    .name {
      color: #657180;
    }
    .cell-bar {
      margin-left: 8px;
      flex: 1;
    }
  }
  .group {
    margin-top: 6;
    padding-top: 6px;
    border-top: 1px solid #E8E8E8;
    .cell {
      flex-direction: column;
      .name {
        width: 100%;
      }
      .cell-bar {
        width: 100%;
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }
</style>

<template>
  <div class="item" @click="monitor" :server-status-search="data.label">
    <div class="title">
      <div class="name">{{data.label}}</div>
      <div class="status">
        <img v-if="loading" src="/static/images/icon/file/loading.gif" style="width: 18px; height: 18px;"/>
        <span class="warning-num" v-else-if="warningNum > 0">{{warningNum}}</span>
        <template v-else>{{data.data.status}}</template>
      </div>
    </div>
    <div class="cell">
      <div class="name">CPU</div>
      <card-bar class="cell-bar" theme="label" :items="[serverStatus.cpu]"/>
    </div>
    <div class="cell">
      <div class="name">内存</div>
      <card-bar class="cell-bar" theme="label" :items="[serverStatus.ram]"/>
    </div>
    <div class="cell">
      <div class="name">磁盘</div>
      <card-bar class="cell-bar" theme="label" :items="[serverStatus.diskSpace]"/>
    </div>
    <div class="group">
      <div class="cell">
        <div class="name">进程CPU占用情况</div>
        <card-bar class="cell-bar" :items="serverPsStatus.cpu"/>
      </div>
      <div class="cell">
        <div class="name">进程内存占用情况</div>
        <card-bar class="cell-bar" :items="serverPsStatus.ram"/>
      </div>
    </div>
  </div>
</template>

<script>
import CardBar from './bar'

import { loadServerStatus, registerReloadTimer, removeReloadTimer } from '../monitor/lib'

export default {
  components: { CardBar },
  props: {
    data: {
      type: Object, default () {
        return {}
      }
    },
    id: { type: Object, default: null }
  },
  data () {
    return {
      status: null,
      loading: false,
      clientRect: null,
      visible: false
    }
  },
  computed: {
    itemId () {
      return this.data && this.data.key ? this.data.key : this.id
    },
    warningNum () {
      if (this.status) {
        return this.status.warningServerStatus.length + this.status.warningServerPsStatus.length
      } else {
        return 0
      }
    },
    serverPsStatus () {
      let data = this.status && this.status.serverPsStatus ? this.status.serverPsStatus : []
      let cpu = [0, 0, 0]
      let ram = [0, 0, 0]
      for (let i = data.length - 1; i >= 0; i--) {
        let item = data[i]
        if (cpu[0] < item.cpu) {
          cpu[0] = item.cpu
        } else if (cpu[1] < item.cpu) {
          cpu[1] = item.cpu
        } else {
          cpu[2] += item.cpu
        }
        if (ram[0] < item.ram) {
          ram[0] = item.ram
        } else if (ram[1] < item.ram) {
          ram[1] = item.ram
        } else {
          ram[2] += item.ram
        }
      }
      return {
        cpu: cpu.map(v => Number((v * 100).toFixed(2))),
        ram: ram.map(v => Number((v * 100).toFixed(2)))
      }
    },
    serverStatus () {
      let data = this.status && this.status.serverStatus ? this.status.serverStatus : {}
      return {
        cpu: Number((data.cpu || 0).toFixed(2)),
        ram: Number((data.ram || 0).toFixed(2)),
        diskSpace: Number((data.diskSpace || 0).toFixed(2))
      }
    }
  },
  watch: {
    itemId: {
      immediate: true,
      handler () {
        this.loadData()
      }
    },
    visible (v) {
      if (v) {
        this.loadData()
      }
    }
  },
  methods: {
    monitor () {
      this.$router.push({
        name: 'server.status.monitor', query: { id: this.itemId }
      })
    },
    async loadData (refresh = false) {
      if (this.visible && !this.loading) {
        this.loading = true
        this.status = await loadServerStatus({serverId: this.itemId}, refresh)
        this.loading = false
      }
    },
    onScroll () {
      let rect = this.$el.getBoundingClientRect()
      let clientRect = this.clientRect
      this.visible = rect.top > (clientRect.top - rect.height) && rect.bottom < (clientRect.bottom + rect.height)
    },
    reload () {
      this.loadData(true)
    }
  },
  mounted () {
    this.clientRect = this.$el.offsetParent.getBoundingClientRect()
    this.onScroll()
    this.$el.offsetParent.addEventListener('scroll', this.onScroll)
    registerReloadTimer(this.reload, 600 * 1000)
  },
  destroyed () {
    this.$el.offsetParent.removeEventListener('scroll', this.onScroll)
    removeReloadTimer(this.reload)
  }
}
</script>
