<style scoped lang="less">
  .card-list {
    display: flex;
    flex-wrap: wrap;
    background-color: #F8FBFF;
    padding: 10px;
    box-sizing: border-box;
    .wrap {
      box-sizing: border-box;
      width: 25%;
      padding: 10px;
    }
  }
</style>

<template>
  <div class="card-list">
    <div class="wrap" v-for="(server, i) in serverList" :key="i">
      <card-item :data="server"></card-item>
    </div>
  </div>
</template>

<script>
import CardItem from './item'
export default {
  components: { CardItem },
  computed: {
    serverList () {
      return this.$store.getters.serverList
    }
  },
  mounted () {
    this.$store.dispatch('loadServerList')
  }
}
</script>
